import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CaseStudy from "../CaseStudy/CaseStudy";


const SolutionsCase = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
         allContentfulSolutionsBuilt(
          sort:{
            fields: createdAt,
            order: DESC
          }
        ) {
           edges {
             node {
               title
               description
               link
               about {
                 childMarkdownRemark {
                   html
                 }
               }
               body {
                 childMarkdownRemark {
                   html
                 }
               }
               tags
               heroImage {
                 file {
                   url
                 }
               }
               logo {
                 file {
                   url
                 }
               }
             }
           }
         }
        }
      `}
      render={data => (
       <CaseStudy data={data}/>
      )}
    />
  )
}

export default SolutionsCase
