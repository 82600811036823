import React, { Component } from 'react'
import styles from "./caseStudy.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';


class CaseStudy extends Component {

  state = {
    readMoreIndex: ""
  }

  renderMore = (i) => {
   this.setState({
     readMoreIndex: i,
   })
  }
  
  showLess = () => {
    this.setState({
      readMoreIndex: "",
    })
  }
  
  render() {
    const { data } = this.props
    return (
      <div className={styles.cases}>
          <div className={styles.container}>
          <Fade bottom>
            <div className={styles.head}>
              <h3>Some Of Our Solutions</h3>
              <p>Below are some of the solutions we have built for clients and they are happy with them.
                We would be glad to help you in your projects also.</p>
            </div>
          </Fade>
            <div className={styles.caseList}>
             {data.allContentfulSolutionsBuilt.edges.map((edge, i) => (
              <div className={styles.case} key={edge.node.title}>
              <Zoom>
                <div className={styles.image}>
                  <img src={edge.node.heroImage.file.url} alt={edge.node.title} />
                  <a className={styles.link} href={edge.node.link} target="_blank" rel="noopener noreferrer">Visit Site <FontAwesomeIcon icon={faAngleRight}/> </a>
                </div>
              </Zoom>
                <div className={styles.content}>
                  <div className={styles.tag}>
                    {edge.node.tags.map((tag, i) => (
                      <span key={i}>{tag}</span>
                    ))}
                  </div>
                  <Slide top>
                    <div>
                      <h4>The Client</h4>
                      <p>{edge.node.description}</p>
                    </div>
                  </Slide>
                  {this.state.readMoreIndex === i ? <> <div>
                        <h4>The Project</h4>
                        <div dangerouslySetInnerHTML={{__html: edge.node.about.childMarkdownRemark.html}}></div>
                      </div>
                  <div>
                    <h4>Our Solution</h4>
                    <div dangerouslySetInnerHTML={{__html: edge.node.body.childMarkdownRemark.html}}></div>
                  </div> 
                   <p className={styles.readmore} onClick={this.showLess}>Show Less</p> </> : <p  className={styles.readmore} onClick={() => this.renderMore(i)}>Read More</p>}
                </div>
              </div>
              ))}
              <div>
                <p className={styles.bronchure}>Want to see more of what we've done?</p>
                <a className={styles.download} href="/files/Brochure.pdf" download>Download Brochure</a>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default CaseStudy
