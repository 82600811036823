import React from "react"
import Layout from "../components/layout"
import SolutionsCase from "../components/SolutionsCase/SolutionsCase"
import SolutionHead from '../components/SolutionHead/SolutionHead'
import SEO from "../components/seo";

const SolutionsBuilt = () => {
  return (
    <Layout>
      <SEO
        title="iQubeLabs | Solutions Built"
        description="Explore the solutions we built in areas such as mobile and web application, data science and intelligent value added services"
      />
      <SolutionHead />
      <SolutionsCase />
    </Layout>
  )
}

export default SolutionsBuilt
